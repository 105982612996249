import * as React from "react"
import * as styles from "./result.module.scss"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../../components/button"
import Footer from "../../components/v2/footer"
import Header from "../../components/v2/header"
import SEO from "../../components/seo"
import Section from "../../components/section"
import { graphql } from "gatsby"

const StrategistResultPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Magnetic Marketing Archetype Quiz | Strategist"
        description="Discover your magnetic marketing archetype and learn how to leverage it to grow your business."
        noIndex={ true }
      />
      <Header btnThem="primary" />
      <main className={ styles.result }>
        <Section theme="dark" style={ { marginTop: `4.5rem`, overflow: `hidden` } }>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <h1 className="color-white"><span className="heading-xs-upper">Your Magnetic Marketing Archetype is an...</span>Strategist!</h1>
              <p className="heading-sm color-white">You are a straight-up visionary! When someone comes to you with a problem, you usually have more than one way to solve it. There’s nothing you can’t accomplish with a good blueprint in one hand and a cup of coffee in the other.</p>
              <p className="heading-sm color-white">You’re naturally curious and often find yourself in research mode, trying to learn everything you can before you come up with a game plan. You pride yourself on knowing what move you want to make at all times and, more importantly, <em>why</em> you want to make it. </p>
              <p className="heading-sm color-white">Sometimes you find yourself so focused on the big picture that it may be hard to remember the small details, but don’t worry, Strategist! That just means you’re leaning into your marketing strengths as a thoughtful and intentional leader.</p>
              <p className="heading-sm color-white">Our advice? Don’t let your knack for strategy keep you from creating simplified processes and systems that will help you take smart action on your plans. After all, your strategy is only as strong as its execution.</p>
            </div>
            <div className="col-lg-5">
              <div className={ styles.quote }>
                <p className="heading-sm color-white">“A vision without a strategy remains an illusion.”<br/><br/><span style={ { color: `#dfe3ff` } }>- Lee Bolman</span></p>
                <svg width="344" height="345" viewBox="0 0 344 345" fill="none" xmlns="http://www.w3.org/2000/svg" style={ { position: `absolute`, right: `-150px`, top: `-200px`, zIndex: `0` } }>
                  <ellipse cx="172" cy="172.121" rx="172" ry="172.121" fill="#23194D"></ellipse>
                </svg>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h3 className="heading-lg color-black text-center">Ready to get personal?</h3>
              <p className="heading-sm color-pink text-center"><strong>Read on for 3 exercises to better understand your archetype!</strong></p>
              <p className="heading-sm color-blue text-center">As a Strategist, you may struggle with feeling drained when you try to implement your awesome strategies. We want to help you make more sales without the hassle and hustle of traditional marketing. Your action plan should be as effective and efficient as you are! Here’s how to get started...</p>
              <div className={ styles.list }>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">1</strong></div>
                    <p className="heading-sm color-black"><strong>Check out the competition.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">You want to make sure your strategies are unique. It’s too easy for businesses in the same niche to blend together or borrow the same ideas from one another — be the one to stand out with your content! (Psst... read <a href="https://www.tryinteract.com/blog/use-your-expertise-to-create-the-perfect-quiz-for-your-audience/" target="_blank" rel="noreferrer">How to Utilize Your Expertise</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">2</strong></div>
                    <p className="heading-sm color-black"><strong>Create automated processes.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">The more you can automate your marketing, the more time you’ll free up for strategic thinking. To make sure you don’t feel bogged down by the day-to-day details, try to implement marketing funnels into your strategy. (Psst... read <a href="https://www.tryinteract.com/blog/marketing-funnel-for-your-quiz/" target="_blank" rel="noreferrer">How to Build a Marketing Funnel</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">3</strong></div>
                    <p className="heading-sm color-black"><strong>Measure your success.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">It’s not enough for you to create a strategy and let it sit to collect dust. The best Strategists are those who know the importance of reviewing the data so they can adjust their strategy over time. This will help you seriously increase engagement and audience retention. (Psst... read <a href="https://www.tryinteract.com/blog/use-quizzes-to-improve-your-customer-service/" target="_blank" rel="noreferrer">How to Constantly Improve Your Customer Service with Data</a> for more tips)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={ { position: `relative`, zIndex: `99` } }>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Hold up — let us introduce ourselves...</h3>
              </div>
            </div>
            <div className="row no-gutters justify-content-center align-items-center">
              <div className="col-lg-6">
                <GatsbyImage image={ getImage(data.aboutImage) } alt="Interact team group picture"/>
              </div>
              <div className="col-lg-6">
                <p className="heading-sm color-black" style={ { background: `white`, padding: `3rem 4rem` } }>We’re the small but mighty team behind Interact, the insanely intuitive quiz builder your business friends already love. We help entrepreneurs and creators just like you make personality-infused quizzes to grow their businesses and reach. Want in on the action? Check your email inbox for more info!</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={ { position: `relative`, zIndex: `9` } }>
            <div className="col-lg-12">
              <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Not sure where to start?</h3>
              <p className="heading-sm color-muted-foreground text-center">These resources will show you how to get started with quiz marketing!</p>
            </div>
            <div className="col-lg-8" style={{ marginTop: '3rem' }}>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Check out our Entrepreneur’s Corner!</strong></p>
                <p className="text-body color-muted-foreground text-center">It’s an archive full of Entrepreneurs’ stories just like yours! We are diving deep into overcoming obstacles when growing a business and showing other new Entrepreneurs that you aren’t alone.</p>
                <Button theme="secondary" type="external" href="https://www.tryinteract.com/blog/">Visit Entrepreneur's Corner</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Join our free Facebook group!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">You can connect with other Influencers like you, and meet some of the other types as you learn about quizzes, email marketing, and growing your business like whoa. Who knows? You may just find your newest business bestie in there!</p>
                <Button theme="secondary" type="external" href="https://www.facebook.com/groups/interactquizcommunity" style={ { margin: `1rem 0` } }>Join Facebook Group</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Follow us on Instagram!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">Folow us to see what we’re up to and get the best tips from successful quiz creators in our community. We’ll see you there!</p>
                <Button theme="secondary" type="external" href="https://www.instagram.com/tryinteract/" style={ { margin: `1rem 0` } }>Follow us on Instagram</Button>
              </div>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default StrategistResultPage

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about/group.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 558,
          quality: 100,
        )
      }
    }
  }
`